cs.ns("app.ui.widget.list")
app.ui.widget.list.model = cs.clazz({
    extend:   app.fw.abstract.model,
    dynamics: {},
    protos:   {

        create() {
            this.base()

            cs(this).model({
                "data:listEntries":   {
                    value: [], valid: "[" +
                    "  {" +
                    "    id: any," +
                    "    name : string," +
                    "    desc : string," +
                    "    clazz : string," +
                    "    clickId?: any" +
                    "  }" +
                    "*]"
                },
                "data:selectedEntry": { value: -1, valid: "any" },
                "event:selectEntry":  { value: null, valid: "any", autoreset: true }
            })
        }

    }
})
